<template>
  <el-row :gutter="20" class="page-box task-list-page">
    <el-col>
      <Breadcrumb
        :home="false"
        :breadcrumbs="[
          {
            label: '用户管理',
            to: '/',
          },
        ]"
      />
    </el-col>
    <el-col>
      <el-card class="grid-card" shadow="never">
        <SchemaTable
          ref="schematableref"
          :schema="schema"
          :model="dataModel"
          :search="search"
          :filters="filters"
          :queryConf="queryConf"
          :hasReset="hasReset"
          :formConf="formConf"
        >
        </SchemaTable>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./user.schema.json";
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";

export default {
  components: {
    SchemaTable,
    Breadcrumb,
  },
  data() {
    return {
      formConf: {
         getServerOptions: this.getServerOptions.bind(this),
      },
      search: "",
      filters: ["username", "roleId"],
      schema,
      hasReset: true,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: uris.user,
        getListMap(item) {
          item.roleId = item.role.name;
          if (item.factory) item.factoryId = item.factory.name;

          item.createdTime = +new Date(item.created_at);
          return item;
        },
        getApi: `${uris.user}${attachId}`,
        getMap(res) {
          res.roleId = res.role.id;
          if (res.factory) res.factoryId = res.factory.id;
          return res;
        },
        deleteApi: `${uris.user}${attachId}`,
        createApi: uris.user,
        updateApi: `${uris.user}${attachId}`,
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    async optionsFormat(list, valueKey = "id", labelKey = "name") {
      if (!Array.isArray(list)) {
        return [];
      }
      let str = JSON.stringify(list)
        .replace(new RegExp(`"${valueKey}":`, "g"), '"value":')
        .replace(new RegExp(`"${labelKey}":`, "g"), '"label":');
      return JSON.parse(str);
    },
    async getServerOptions(scenario) {
      const optionMd = new DataModel({
        getApi: "/api/v1/role",
      });
      const res = await optionMd.get();
      let options = await this.optionsFormat(res.list);
      if (scenario === 'create') {
        options = options.filter(item => {
          if (item.value !== '3') {
            return true;
          }
        });
      }
      return options;
    },
  },
};
</script>

<style scoped>
</style>